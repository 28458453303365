<template>
  <v-card>
    <v-toolbar dark dense :color="estudio.cargador?.estado_actual_int?.color">
      <v-icon left>{{ estudio.cargador?.estado_actual_int?.icon }}</v-icon>
      <v-toolbar-title
        >{{ estudio.idEstudio }} - {{ estudio.nombreProyecto }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn small icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <div class="pa-4 d-flex flex-column" style="gap: 20px">

      <div
        style="display: grid; grid-template-columns: 0fr 1fr; gap: 10px"
        class="align-center"
      >
        <v-icon large>mdi-credit-card</v-icon>
        <h4>Pagos</h4>
        <div></div>
        <PagosInstalacion
          :key="estudio.idEstudio"
          :idEstudio="estudio.idEstudio"
          tipo="cargador"
        />
      </div>

      <div
        style="display: grid; grid-template-columns: 0fr 1fr; gap: 10px"
        class="align-center"
      >
        <v-icon large>mdi-text-short</v-icon>
        <h4>Datos</h4>
        <div></div>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              hide-details
              label="Direccion"
              readonly
              v-model="estudio.cups_principal.direccion"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              hide-details
              label="GPS"
              readonly
              :value="[estudio.latitud, estudio.longitud].join('   ')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <GestionTarjeta
              title="Instaladora asignada"
              :value="estudio.instaladora?.nombreInstaladora"
              icon="mdi-wrench"
              prevent-default-editing
              :editing="cambios.instaladora"
              @editing="(v) => (cambios.instaladora = v)"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <GestionTarjeta
              title="Comercial asignado"
              :value="
                [estudio.comercial, estudio.subComercial]
                  .filter((x) => x)
                  .join(' - ')
              "
              icon="mdi-account"
              prevent-default-editing
              :editing="cambios.comercial"
              @editing="(v) => (cambios.comercial = v)"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <GestionTarjeta
              title="Responsable"
              :value="estudio.responsable?.nombre || 'Sin responsable asignado'"
              icon="mdi-account-tie"
              :editable="false"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <!-- <v-text-field
              outlined
              hide-details
              type="date"
              label="Fecha firma estudio"
              v-model="estudio.cargador.fechaFirmaEstudio"
            ></v-text-field> -->
            <v-date-field
              label="Fecha firma estudio"
              v-model="estudio.cargador.fechaFirmaEstudio"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-date-field
              label="Fecha firma contrato"
              v-model="estudio.cargador.fechaFirmaContrato"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-date-field
              label="Fecha fin obra"
              v-model="estudio.cargador.fechaFirmaObra"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              hide-details
              type="number"
              label="Tipo de cargador"
              v-model="estudio.cargador.tipo"
              :items="[
                { text: 'Monofásico', value: 'MONOFASICO' },
                { text: 'Trifásico', value: 'TRIFASICO' },
              ]"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              hide-details
              type="number"
              label="Metros cable"
              suffix="m"
              v-model="estudio.cargador.metrosCable"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              hide-details
              type="number"
              label="Nº Tomas"
              v-model="estudio.cargador.nTomas"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              hide-details
              type="number"
              clearable
              label="Tipo conector"
              v-model="estudio.cargador.tipoConector"
              :items="tiposConector"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              hide-details
              clearable
              type="number"
              label="Precio venta"
              suffix="€"
              v-model="estudio.cargador.precioVenta"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              hide-details
              type="number"
              label="Precio compra"
              suffix="€"
              v-model="estudio.cargador.precioCompra"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              label="Tipo de cliente"
              :items="tiposCliente"
              v-model="estudio.cargador.idTipoCliente"
              outlined
              hide-details
              clearable
              item-text="nombre"
              item-value="idTipo"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              hide-details
              type="number"
              label="Cable repuesto"
              v-model="estudio.cargador.cableRepuesto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              hide-details
              type="number"
              label="Cargador portátil"
              v-model="estudio.cargador.cargadorPortatil"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined
              no-resize
              rows="1"
              auto-grow
              hide-details
              label="Comentario"
              v-model="estudio.cargador.comentario"
            ></v-textarea>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              :loading="loading"
              small
              color="primary"
              @click.stop="updateCargador"
            >
              <v-icon left>mdi-content-save</v-icon>
              Guardar</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <div
        style="display: grid; grid-template-columns: 0fr 1fr; gap: 10px"
        class="align-center"
      >
        <v-icon large>mdi-paperclip</v-icon>
        <h4>Adjuntos <span>interno</span></h4>
        <div></div>
        <DocumentacionEstudio :idEstudio="idEstudio" sub="adjuntos" />
      </div>
      <div
        style="display: grid; grid-template-columns: 0fr 1fr; gap: 10px"
        class="align-center"
      >
        <v-icon large>mdi-paperclip</v-icon>
        <h4>Documentacion Final</h4>
        <div></div>
        <DocumentacionEstudio :idEstudio="idEstudio" sub="final" />
      </div>
      <div
        style="display: grid; grid-template-columns: 0fr 1fr; gap: 10px"
        class="align-center"
        v-if="estudio.cronograma"
      >
        <v-icon large>mdi-timeline-outline</v-icon>
        <h4>Cronograma</h4>
        <div></div>
        <cronograma-estudio
          :idEstudio="estudio.idEstudio"
          :cronograma="estudio.cronograma"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  components: {
    VDateField: () => import("@/components/VDateField.vue"),
    PagosInstalacion: () => import("./PagosInstalacion.vue"),
    DocumentacionEstudio: () =>
      import("@/modules/estudios/components/documentacion/index"),
    InstaladorasEstudios: () => import("../InstaladorasEstudios.vue"),
    CambiarComercial: () => import("../CambiarComercial.vue"),
    GestionTarjeta: () => import("../gestion/GestionTarjeta.vue"),
    DetallesEstudio: () => import("../../views/DetallesEstudio.vue"),
    CalendarioEstudio: () => import("../calendario/CalendarioEstudio.vue"),
    TramitacionesInstalacion: () => import("./TramitacionesInstalacion.vue"),
    CronogramaEstudio: () => import("@/components/CronogramaEstudio.vue"),
  },
  props: {
    idEstudio: Number,
  },
  data() {
    return {
      estudio: {
        estado_actual: {},
        estado_actual_int: {},
        cups_principal: {},
        cargador: {},
        tramitaciones: [],
      },
      estudio_freeze: {},
      cambios: {
        instaladora: false,
        comercial: false,
      },
      detailsId: null,
      loading: false,
      tiposConector: [],
      tiposCliente: [],
    };
  },
  mounted() {
    if (this.idEstudio) {
      this.getEstudio();
      this.getTiposCliente();
      this.getTiposMangueras();
    }
  },
  methods: {
    async getEstudio() {
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/estudios/${this.idEstudio}`,
      });

      data.cargador ??= {};
      data.tramitaciones ??= [];

      data.cronograma = data.cronograma.filter( x => x.idInstalacion )

      this.estudio = data;
      this.estudio_freeze = { ...this.estudio };
    },
    async updateCargador() {
      try {
        this.loading = true;
        const { data } = await axios({
          url: `${process.env.VUE_APP_API_URL}/cargadores/${this.idEstudio}`,
          method: "POST",
          data: {
            ...this.estudio.cargador,
          },
        });

        // this.getEstudio();
        this.estudio.cargador = data;
        this.$root.$emit("snack", "Se han guardado los cambios");
        this.$emit("reload");
        this.getEstudio();
      } catch {
        this.$root.$emit("snack", "Ha ocurrido un error inesperado", "error");
      } finally {
        this.loading = false;
      }
    },
    async getTiposCliente() {
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/tipo_cliente`,
      });
      this.tiposCliente = data;
    },
    async getTiposMangueras() {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}/cargadores/tipos_manguera`,
      });
      this.tiposConector = Object.entries(data).map(([k, v]) => ({
        text: v,
        value: k,
      }));
    },
  },
};
</script>
